import { useMutation } from "@apollo/client";
import { SUBSCRIPTION_CANCEL } from "graphql/mutations";

export default options => {
  const [subscriptionCancel, subscriptionCancelResults] = useMutation(
    SUBSCRIPTION_CANCEL,
    options
  );

  return [
    ({ variables }) => {
      return subscriptionCancel({
        variables,
      });
    },
    subscriptionCancelResults,
  ];
};
