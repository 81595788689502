import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import { useFirebaseConnect } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";
import { setCancelReasonAction } from "modules/subscription";
import useUserSubscription from "hooks/Subscriptions/useUserSubscription";
import { getUserSubscription } from "modules/user/actions";
import useSubscriptionCancel from "hooks/Subscriptions/useSubscriptionCancel";
import Alert from "app/components/Alert";
import SpanLink from "app/components/SpanLink";
import { PageBody } from "app/components/RouteComponents";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { AccountBodyWrapper } from "../AccountBodyWrapper";

const Wrapper = styled.div``;

const H1 = styled.h1`
  font-size: 18px;
  font-weight: bold;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Bullet = styled.li`
  list-style-type: disc;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
`;

const Cancel = () => {
  useFirebaseConnect(["classesMetadata/count"]);
  const history = useHistory();
  const dispatch = useDispatch();

  const classesCount = useSelector(
    ({ firebase }) => firebase.data?.classesMetadata?.count || 1340
  );
  const cancelReason = useSelector(
    ({ subscription }) => subscription.cancelReason
  );

  const [data = {}, loading] = useUserSubscription();
  const [subscriptionCancel, subscriptionCancelResults] = useSubscriptionCancel(
    {
      onCompleted: () => {
        dispatch(getUserSubscription());
        history.push("/account/subscription");
      },
    }
  );

  if (loading) {
    return <LoaderCentered />;
  }

  const {
    me: { primarySubscription },
  } = data;
  const { id: subscriptionId } = primarySubscription;

  const { error: subscriptionCancelError } = subscriptionCancelResults;

  const handleSubscriptionCancel = () => {
    subscriptionCancel({
      variables: {
        subscriptionId,
        cancelReason,
      },
    });
    dispatch(setCancelReasonAction(null));
  };

  return (
    <Wrapper>
      <Helmet title="Cancel | Online Dance Classes and Tutorials" />
      <PageBody>
        <H1>Are you sure you want to cancel?</H1>
        <p>You will not be able to:</p>
        <ul>
          <Bullet>
            Access our {classesCount}+ classes from the world&apos;s best
            instructors
          </Bullet>
          <Bullet>Dance and practice at your own pace</Bullet>
          <Bullet>Learn new dance styles</Bullet>
          <Bullet>Take dance classes anywhere with our mobile app</Bullet>
        </ul>
        <hr />
        <Actions>
          <StyledLink to="/account/subscription">
            No, don&apos;t cancel my subscrition
          </StyledLink>
          <SpanLink grey underline right onClick={handleSubscriptionCancel}>
            Yes, cancel my subscription
          </SpanLink>
        </Actions>
        {subscriptionCancelError && (
          <Alert variant="danger">
            There was an error while canceling. Please try again or contact us!
          </Alert>
        )}
      </PageBody>
    </Wrapper>
  );
};

export default () => (
  <AccountBodyWrapper>
    <Cancel />
  </AccountBodyWrapper>
);
