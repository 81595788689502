import React from "react";
import {
  NavItem,
  NavItemList,
  NavContainer,
} from "app/components/NavComponents";
import { H1 } from "app/components/Typography";
import { Container, CenterWrapper } from "app/components/RouteComponents";
import styled from "styled-components";
import ScrollToTopOnMount from "app/components/ScrollToTopOnMount";
import {
  useFamilyFriendlyFlag,
  useAreNotificationsAvailable,
} from "hooks/useFeatureFlags";
import { useGetParentalControlsQuery } from "services/graphql";
import { profileSelectElement } from "services/typewriter/segment";
import {
  ANALYTICS_LOCATION,
  ANALYTICS_MODULE,
  ANALYTICS_ELEMENT_NAME,
  ANALYTICS_ELEMENT_TYPE,
} from "constants/index";
import { SpaceProps } from "styled-system";
import { Routes } from "constants/routes";

interface Props {
  children: React.ReactNode;
  containerProps?: SpaceProps;
}

const Options = styled(NavItem)`
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
`;

export function AccountBodyWrapper({ children, containerProps }: Props) {
  const areNotificationsFeaturesAvailable = useAreNotificationsAvailable();
  const isFamilyFeatureEnabled = useFamilyFriendlyFlag();
  const { data: userData } = useGetParentalControlsQuery();
  const isParentalControlsEnabled = userData?.me?.parentalControls?.isEnabled;

  const analyticsProfileSelectElement = () => {
    profileSelectElement({
      element_name: ANALYTICS_ELEMENT_NAME.accountSteezyFamily,
      element_type: ANALYTICS_ELEMENT_TYPE.list_element,
      module: ANALYTICS_MODULE.account,
      location: ANALYTICS_LOCATION.profile,
      pin_enabled: isParentalControlsEnabled,
    });
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <H1 my={4} mx={3}>
        Account
      </H1>
      <NavContainer>
        <NavItemList>
          <Options exact to={Routes.account__settings}>
            Account Settings
          </Options>
          <Options exact to={Routes.account__preferences}>
            Dance Preferences
          </Options>
          <Options exact to={Routes.account__subscription}>
            Manage Subscription
          </Options>
          {areNotificationsFeaturesAvailable && (
            <Options exact to={Routes.account__manage_notifications}>
              Notifications
            </Options>
          )}
          {isFamilyFeatureEnabled && (
            <Options
              exact
              to={Routes.account__family}
              onClick={analyticsProfileSelectElement}
            >
              STEEZY Family
            </Options>
          )}
        </NavItemList>
      </NavContainer>
      <CenterWrapper>
        <Container {...containerProps}>{children}</Container>
      </CenterWrapper>
    </div>
  );
}
