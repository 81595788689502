import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { color, space, system, typography } from "styled-system";
import Flex from "app/components/Flex";

const activeStyling = () => `
  color: black;
  border-bottom: 2px solid;
`;

export const NavItem = styled(NavLink).attrs(() => ({
  color: "grey",
  p: 3,
  fontSize: "12px",
  fontWeight: "bold",
  textTransform: "uppercase",
  letterSpacing: "2.2px",
  lineHeight: "auto",
}))`
  display: inline-block;
  white-space: nowrap;
  ${color}
  ${space}
  ${typography}
  ${system({
    textTransform: true,
  })}

  &.active {
    ${activeStyling}
  }
`;

export const NavContainer = styled.div`
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.lightGrey};
  display: flex;
  justify-content: center;
`;

export const NavItemList = styled(Flex).attrs({
  width: "100%",
  bg: "white",
})`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
  }

  /* Only need to add if list is scrolling */
  /* :after {
    content: "";
    flex: 0 0 20px;
  } */
`;
