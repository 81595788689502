import styled from "styled-components";
import { H2, P } from "app/components/Typography";
import Div from "app/components/Div";
import Flex from "app/components/Flex";

export const PageTitle = H2;

export const PageDescription = styled(P).attrs({
  width: ["100%", "100%", "75%", "50%"],
  my: 3,
})``;

export const PageBody = styled(Div).attrs({
  width: "100%",
  my: 3,
  px: { _: 3, md: 0 },
})``;

export const CenterWrapper = styled(Flex).attrs({
  justifyContent: "center",
  pb: 4,
})``;

export const Container = styled(Div).attrs(props => ({
  px: [3, 3, 4],
  pt: [4, 4, 4, "42px"],
  pb: 2,
  maxWidth: "1600px",
  width: "100%",
  ...props,
}))``;
